/* eslint-disable security-node/detect-crlf */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
import { AuthType } from "@passwordless-id/webauthn/dist/esm/types";
import {
  IHybridPaymaster,
  SponsorUserOperationDto,
  UserOperationStruct,
  PaymasterAndDataResponse,
  BiconomySmartAccountV2,
  PaymasterMode,
  FeeQuotesOrDataResponse,
  Transaction,
} from "@biconomy/account";
import { BigNumber, ethers } from "ethers";
import { sha256 } from "js-sha256";
import chalk from "chalk";
import toast from "react-hot-toast";
import localforage from "localforage";
import CryptoJs from "crypto-js";

import erc20ABI from "../constants/erc20ABI";
import {
  ASSET_TYPE,
  ERROR_EVENTS,
  CUSTOM_EVENTS,
  NATIVE_ADDRESS,
  NATIVE_MATIC_ADDRESS,
  STORAGE_KEYS,
  ERROR_TOAST_MESSAGES,
  TIME,
  LOCK_TIME,
  WALLET_TYPE,
  DEVICE_AUTH_TYPE,
} from "../constants/Enums";
import erc721ABI from "../constants/erc721ABI";
import {
  SelectedTokenForGasType,
  TransactionData,
  AllPreviousTransactions,
  DeviceType,
  TransferStateType,
  RawTransactionObjectType,
  AssetType,
  UserSettingsType,
  DappsConnectionsListType,
} from "../constants/Types";
import { getChains } from "./api";
import {
  DEFAULT_NETWORK,
  FP_KEY,
  NODE_ENV,
  FIAT_API,
  FIAT_API_KEY,
} from "../config/env";

import manifest from "../manifest/manifest.json";
import { UserSettingsDefault } from "../constants/TypesDefaults";

export const sleep = (ms: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, ms));

export const isAddressSame = (address1: string, address2: string): boolean =>
  String(address1).toLowerCase() === String(address2).toLowerCase();

export const getItemFromStorage: any = (
  key: string,
  storage = "localStorage",
) => {
  const _window = window as any;
  const item: any = _window[storage].getItem(key);
  let result = null;

  try {
    result = item ? JSON.parse(item) : null;
  } catch {
    result = item;
  }
  return result;
};

export const setItemInStorage: any = (
  name: any,
  data: any,
  storage = "localStorage",
) => {
  const _window = window as any;

  _window[storage].setItem(name, JSON.stringify(data));
};

export const removeItemFromStorage: any = (
  name: any,
  storage = "localStorage",
) => {
  const _window = window as any;

  _window[storage].removeItem(name);
};

export const getDevelopModeData: any = async () =>
  (await localforage.getItem(STORAGE_KEYS.DEVELOPER_MODE)) ||
  getItemFromStorage(STORAGE_KEYS.DEVELOPER_MODE) ||
  false;

export const getUserSettingsData = async (): Promise<UserSettingsType[]> =>
  (await localforage.getItem<UserSettingsType[]>(STORAGE_KEYS.USER_SETTINGS)) ||
  [];

export const getDappConnectionsData = async (
  connectedAccountAddress: string,
  getAll?: boolean,
) => {
  const connectionsData: any =
    (await localforage.getItem(STORAGE_KEYS.DAPP_CONNECTIONS)) || {};

  if (getAll) return connectionsData;

  return connectionsData?.[connectedAccountAddress] || [];
};

export const setUserSettingsData: any = async (data: UserSettingsType[]) => {
  await localforage.setItem(STORAGE_KEYS.USER_SETTINGS, data);
};

export const getActiveUserDevice = async (
  EOA: string,
): Promise<UserSettingsType> => {
  const userWallets: UserSettingsType[] = await getUserSettingsData();

  const activeWallet = userWallets.find((wallet) =>
    isAddressSame(wallet.address, EOA),
  );

  return activeWallet || UserSettingsDefault;
};

export const getDeveloperAdOption = async (EOA: string): Promise<boolean> => {
  const usersSettings: UserSettingsType[] = await getUserSettingsData();
  const userIndex = usersSettings.findIndex((user: UserSettingsType) =>
    isAddressSame(user.address, EOA),
  );

  if (userIndex !== -1) {
    return (
      usersSettings[userIndex]?.settings?.developerMode?.turnOffAds || false
    );
  }

  return false;
};

export const getDevicesFromStorage = () =>
  getItemFromStorage(STORAGE_KEYS.DEVICES) || [];

export const getShortDisplayString: any = (
  address: string,
  showValue: boolean,
) => {
  const firstFourDigit = address?.slice(0, 4);
  const lastFourDigit = address?.slice(Number(address?.length) - 4);

  if (showValue) return `${firstFourDigit}...${lastFourDigit}`;

  return (
    <>
      {firstFourDigit}...{lastFourDigit}
    </>
  );
};

let Chains: any[] = [];

export const setChains = async (chains: any) => {
  if (chains && chains.length) Chains = chains;
  else Chains = await getChains();
};

export const getChainDetails: any = (chainId: number) => {
  let chainData;

  Chains.forEach((chain: any) => {
    if (chain.chainId === chainId) {
      chainData = chain;
    }
  });

  return chainData;
};

export const constructTransactionData: any = (
  transactions: any,
): Transaction[] => {
  const txns: Transaction[] = [];

  transactions.map(({ to, args, value, from, assetType }: any) => {
    let txn: any = {};

    if (assetType === ASSET_TYPE.TOKEN) {
      const contract = args.length
        ? new ethers.utils.Interface(erc20ABI)
        : null;

      const data = contract
        ? contract.encodeFunctionData("transfer", args)
        : "0x";

      txn = {
        from,
        to,
        data,
        value: value || "0",
      };
    } else if (assetType === ASSET_TYPE.NFT) {
      const nftContract = args.length
        ? new ethers.utils.Interface(erc721ABI)
        : null;

      const data = nftContract
        ? nftContract.encodeFunctionData("safeTransferFrom", args)
        : "0x";

      txn = {
        from,
        to,
        data,
      };
    }

    txns.push(txn);
  });

  return txns;
};

export const constructFinalUserOp: any = async (
  smartAccountInstance: BiconomySmartAccountV2,
  partialUserOp: Partial<UserOperationStruct>,
  gasFeeAddress: string,
) => {
  const paymaster =
    smartAccountInstance.paymaster as IHybridPaymaster<SponsorUserOperationDto>;
  const feeQuotesResponse: FeeQuotesOrDataResponse =
    await paymaster.getPaymasterFeeQuotesOrData(partialUserOp, {
      mode: PaymasterMode.ERC20,
      tokenList: [gasFeeAddress],
    });

  if (
    !feeQuotesResponse.feeQuotes ||
    !feeQuotesResponse.feeQuotes.length ||
    !feeQuotesResponse.feeQuotes[0]
  ) {
    return null;
  }

  if (!feeQuotesResponse.tokenPaymasterAddress) {
    return null;
  }

  const requiredFeeQuotes = feeQuotesResponse.feeQuotes[0];
  const spender = feeQuotesResponse.tokenPaymasterAddress;

  const finalUserOp = await smartAccountInstance.buildTokenPaymasterUserOp(
    partialUserOp,
    { feeQuote: requiredFeeQuotes, spender, maxApproval: false },
  );
  const paymasterServiceData = {
    mode: PaymasterMode.ERC20,
    feeTokenAddress: requiredFeeQuotes.tokenAddress,
  };

  try {
    const paymasterAndDataWithLimits: PaymasterAndDataResponse =
      await paymaster.getPaymasterAndData(finalUserOp, paymasterServiceData);

    finalUserOp.paymasterAndData = paymasterAndDataWithLimits.paymasterAndData;

    if (
      paymasterAndDataWithLimits.callGasLimit &&
      paymasterAndDataWithLimits.verificationGasLimit &&
      paymasterAndDataWithLimits.preVerificationGas
    ) {
      finalUserOp.callGasLimit = paymasterAndDataWithLimits.callGasLimit;
      finalUserOp.verificationGasLimit =
        paymasterAndDataWithLimits.verificationGasLimit;
      finalUserOp.preVerificationGas =
        paymasterAndDataWithLimits.preVerificationGas;
    }

    return finalUserOp;
  } catch (e) {
    console.log("Error in constructing final user op : ", e);
    return null;
  }
};

export const constructFinalUserOpForGasless: any = async (
  smartAccountInstance: BiconomySmartAccountV2,
  partialUserOp: Partial<UserOperationStruct>,
) => {
  const paymaster =
    smartAccountInstance.paymaster as IHybridPaymaster<SponsorUserOperationDto>;

  const paymasterServiceData = { mode: PaymasterMode.SPONSORED };
  const finalUserOp = partialUserOp;

  try {
    const paymasterAndDataWithLimits: PaymasterAndDataResponse =
      await paymaster.getPaymasterAndData(partialUserOp, paymasterServiceData);

    finalUserOp.paymasterAndData = paymasterAndDataWithLimits.paymasterAndData;

    if (
      paymasterAndDataWithLimits.callGasLimit &&
      paymasterAndDataWithLimits.verificationGasLimit &&
      paymasterAndDataWithLimits.preVerificationGas
    ) {
      finalUserOp.callGasLimit = paymasterAndDataWithLimits.callGasLimit;
      finalUserOp.verificationGasLimit =
        paymasterAndDataWithLimits.verificationGasLimit;
      finalUserOp.preVerificationGas =
        paymasterAndDataWithLimits.preVerificationGas;
    }

    return finalUserOp;
  } catch (e) {
    console.log("Error in constructing final gasless user op : ", e);
    return null;
  }
};

const isValidContract = async (address: string, provider: any) => {
  const code = await provider.getCode(address);

  if (code === "0x") {
    return false;
  }

  return true;
};

export const getTokenData = async (
  tokenAddress: string,
  provider: any,
  userAddress: string,
) => {
  const isValid = await isValidContract(tokenAddress, provider);

  if (!isValid) {
    return null;
  }

  const contract = new ethers.Contract(tokenAddress, erc20ABI, provider);

  const name = await contract.name();
  const symbol = await contract.symbol();
  const decimals = await contract.decimals();

  let balance = await contract.balanceOf(userAddress);

  balance = ethers.utils.formatUnits(balance, decimals);

  return { name, symbol, balance, decimals };
};

const tokenBalance: any = {};

export const getTokenBalance = async (
  tokenAddress: string,
  provider: any,
  userAddress: string,
  setBalance?: any,
) => {
  if (
    !tokenBalance ||
    !tokenBalance[`${userAddress}`] ||
    !tokenBalance[`${userAddress}`][`${tokenAddress}`] ||
    tokenBalance[`${userAddress}`][`${tokenAddress}`]?.time <
      Number(new Date()) - 5000
  ) {
    const contract = new ethers.Contract(tokenAddress, erc20ABI, provider);

    let decimals;

    if (
      tokenBalance &&
      tokenBalance[`${userAddress}`] &&
      tokenBalance[`${userAddress}`][`${tokenAddress}`] &&
      tokenBalance[`${userAddress}`][`${tokenAddress}`]?.decimals
    )
      decimals = tokenBalance[`${userAddress}`][`${tokenAddress}`]?.decimals;
    else decimals = await contract.decimals();

    let balance = await contract.balanceOf(userAddress);

    tokenBalance[`${userAddress}`] = {
      ...tokenBalance[`${userAddress}`],
      [`${tokenAddress}`]: {
        time: Number(new Date()),
        balance,
        decimals,
      },
    };
    balance = ethers.utils.formatUnits(balance, decimals);
    if (setBalance) {
      setBalance(balance.toString());
    }

    return balance.toString();
  }

  if (setBalance) {
    setBalance(
      ethers.utils.formatUnits(
        tokenBalance[`${userAddress}`][`${tokenAddress}`].balance,
        tokenBalance[`${userAddress}`][`${tokenAddress}`].decimals,
      ),
    );
  }

  return ethers.utils.formatUnits(
    tokenBalance[`${userAddress}`][`${tokenAddress}`].balance,
    tokenBalance[`${userAddress}`][`${tokenAddress}`].decimals,
  );
};

const coinBalance: any = {};

export const getCoinBalance = async (
  userAddress: string,
  provider: any,
  setBalance?: any,
) => {
  if (
    !coinBalance ||
    !coinBalance[`${userAddress}`] ||
    coinBalance[`${userAddress}`]?.time < Number(new Date()) - 5000
  ) {
    const balance = await provider.getBalance(userAddress);

    coinBalance[`${userAddress}`] = {
      time: Number(new Date()),
      balance,
    };

    if (setBalance) {
      setBalance(ethers.utils.formatEther(balance));
    }

    return ethers.utils.formatEther(balance);
  }

  if (setBalance) {
    setBalance(ethers.utils.formatEther(coinBalance[`${userAddress}`].balance));
  }

  return ethers.utils.formatEther(coinBalance[`${userAddress}`].balance);
};

export const generateSHA256Hash = (data: string) => sha256(data);

export const encryptData = (data: string, encryptionString: string) =>
  CryptoJs.AES.encrypt(data, encryptionString).toString();

export const decryptData = (data: string, encryptionString: string) =>
  CryptoJs.AES.decrypt(data, encryptionString).toString(CryptoJs.enc.Utf8);

export const log = (message: string, data: any = {}, type = "info") => {
  if (NODE_ENV !== "development") return;

  if (type === "info") {
    console.log(chalk.blue(message), data);
  } else if (type === "error") {
    console.log(chalk.red(message), data);
  } else if (type === "success") {
    console.log(chalk.green(message), data);
  } else if (type === "warning") {
    console.log(chalk.yellow(message), data);
  }
};

export const copyToClipboard = async (data: any, msg?: string) => {
  try {
    await navigator.clipboard.writeText(data);
    let textMsg = "Text Copied To clipboard";

    if (msg) {
      textMsg = msg;
    }

    toast.success(textMsg, { id: "address copied" });
  } catch (error) {
    log("Copy failed due to: ", error, "error");
  }
};

export const formatDateToWords = (inputDate: string): string => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [year, month, day] = inputDate.split("-");
  const monthIndex = parseInt(month, 10) - 1;

  return `${months[monthIndex]} ${parseInt(day, 10)}, ${year}`;
};

export const checkIsNativeToken = (tokenAddress: string): boolean =>
  tokenAddress.toString().toLowerCase() === NATIVE_ADDRESS.toLowerCase() ||
  tokenAddress.toString().toLowerCase() === NATIVE_MATIC_ADDRESS.toLowerCase();

export const fixPrecisionToDigits = (num: number, precisionDigit: number) => {
  const numString = num.toString();

  const decimalIndex = numString.indexOf(".");

  let i = precisionDigit;

  while (i < 19) {
    if (numString.indexOf(`e-${i.toString()}`) >= 0) return "~0";

    i += 1;
  }

  if (
    decimalIndex !== -1 &&
    numString.length - decimalIndex - 1 > precisionDigit
  ) {
    const roundedNum = Number(num.toFixed(precisionDigit));

    return roundedNum.toString();
  }

  return numString;
};

export const getActiveDevice = (devices: any, smartAccount: string) =>
  devices.filter((device: any) => device.address === smartAccount)[0];

export const initialSelectedTokenForGas = (
  chainId: number,
): SelectedTokenForGasType => ({
  icon: getChainDetails(chainId).coinUri,
  tokenName: getChainDetails(chainId).name,
  tokenSymbol: getChainDetails(chainId).nativeAsset,
  tokenAddress: NATIVE_ADDRESS,
  tokenGasValue: 0,
});

export const mouseMoveWhilstDown = (target: any, whileMove: any) => {
  const endMove = function () {
    window.removeEventListener("mousemove", whileMove);
    window.removeEventListener("mouseup", endMove);
  };

  target.addEventListener("mousedown", (event: any) => {
    event.stopPropagation(); // remove if you do want it to propagate ..
    window.addEventListener("mousemove", whileMove);
    window.addEventListener("mouseup", endMove);
  });
};

export const getAccentColor = async (_chainId: any): Promise<string> => {
  const eoa = getItemFromStorage(STORAGE_KEYS.SIGNER);

  try {
    const usersSettings: UserSettingsType[] = await getUserSettingsData();

    const userIndex = usersSettings.findIndex(
      (user: UserSettingsType) => user.address === eoa,
    );

    if (userIndex !== -1) {
      const userTheme = usersSettings[userIndex].settings.preferences?.theme;

      if (!userTheme || userTheme === "default") {
        const currentChainDetails = getChainDetails(_chainId);

        if (currentChainDetails) {
          return currentChainDetails.accentColor;
        }

        return "#000000";
      }

      return userTheme;
    }

    const currentChainDetails = getChainDetails(_chainId);

    if (currentChainDetails) {
      return currentChainDetails.accentColor;
    }

    return "#000000";
  } catch (error) {
    log("Error fetching address book entries:", error);
    return "#000000";
  }
};

export const getNativeAssetGas = async (
  partialUserOp: Partial<UserOperationStruct>,
  setNativeAssetGas: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (!partialUserOp) setNativeAssetGas("0");

  const { callGasLimit, maxFeePerGas } = partialUserOp;

  if (!callGasLimit || !maxFeePerGas) setNativeAssetGas("0");

  const bnCallGasLimit = BigNumber.from(callGasLimit || 0);
  const bnMaxFeePerGas = BigNumber.from(maxFeePerGas || 0);

  const gasPrice: string = ethers.utils.formatUnits(
    bnCallGasLimit.mul(bnMaxFeePerGas).toString(),
    "ether",
  );

  setNativeAssetGas(gasPrice);
};

export const getNativeAssetGasForEoa = async (
  provider: any,
  tx: any,
  setNativeAssetGas: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (!provider) setNativeAssetGas("0");

  const feeData = await provider?.getFeeData();

  const fees = await provider.estimateGas(tx);

  const gasPrice = ethers.utils.formatUnits(
    feeData.gasPrice.mul(fees).toString(),
    "ether",
  );

  setNativeAssetGas(gasPrice);
};

export const setTransactionDataInLocal = async (
  smartAccountAddress: string,
  chainId: number,
  transactionData: TransactionData,
) => {
  const previousTransactions: AllPreviousTransactions =
    (await localforage.getItem(STORAGE_KEYS.PREVIOUS_TRANSACTIONS)) || {};

  previousTransactions[smartAccountAddress] =
    previousTransactions[smartAccountAddress] || {};
  previousTransactions[smartAccountAddress][chainId] =
    previousTransactions[smartAccountAddress][chainId] || {};

  previousTransactions[smartAccountAddress][chainId][transactionData.date] =
    previousTransactions[smartAccountAddress][chainId][transactionData.date] ||
    [];

  previousTransactions[smartAccountAddress][chainId][
    transactionData.date
  ].unshift(transactionData);
  await localforage.setItem(
    STORAGE_KEYS.PREVIOUS_TRANSACTIONS,
    previousTransactions,
  );
};

export const setDappConnectionsData = async (
  data: DappsConnectionsListType,
) => {
  await localforage.setItem(STORAGE_KEYS.DAPP_CONNECTIONS, data);
};

export const removeDappConnectionsData = async (
  connectedWallet: string,
  hostname: string,
) => {
  const allConnectedDapps = await getDappConnectionsData(connectedWallet, true);
  const dappConnections = allConnectedDapps[connectedWallet] || {};

  if (Object.keys(dappConnections).length === 0) {
    return;
  }

  const newData = dappConnections.filter(
    (item: any) => item.hostname !== hostname,
  );

  if (!newData.length) {
    await localforage.setItem(STORAGE_KEYS.DAPP_CONNECTIONS, {
      [connectedWallet]: [],
    });
  } else {
    localforage.setItem(STORAGE_KEYS.DAPP_CONNECTIONS, {
      [connectedWallet]: [...newData],
    });
  }
};

export const formatDateFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatUnixTimestamp = (unixTimestamp: number): string => {
  const date = new Date(unixTimestamp * 1000);
  const hours: string = String(date.getUTCHours()).padStart(2, "0");
  const minutes: string = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds: string = String(date.getUTCSeconds()).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export const generateEoaWithMnemonic = (mnemonic: string) =>
  ethers.Wallet.fromMnemonic(mnemonic);

export const generateEoaWithCredentialId = (
  credentialId: string,
): { eoa: any; mnemonic: any } => {
  const inputBytes = ethers.utils.toUtf8Bytes(credentialId);
  const hash = ethers.utils.keccak256(inputBytes);

  const mnemonic = ethers.utils.entropyToMnemonic(hash);
  const eoa = generateEoaWithMnemonic(mnemonic);

  return { mnemonic, eoa };
};

// TODO this function can be removed as we will not be using it.
// update it in the recover phrase page as well
export const generateMnemonicAndEoa = (
  device: UserSettingsType,
): { eoa: any; mnemonic: any } => {
  if (!device) {
    return { eoa: null, mnemonic: null };
  }

  if (device.id) {
    if (device.mnemonic) {
      const mnemonic = decryptData(device?.mnemonic, device?.id);

      const eoa = generateEoaWithMnemonic(mnemonic);

      return {
        mnemonic,
        eoa,
      };
    }

    return generateEoaWithCredentialId(device.id);
  }

  return { eoa: null, mnemonic: null };
};

export const getRawTransactionObject = (
  transferData: TransferStateType,
  smartAccountAddress: string,
) => {
  const rawTransactionObject: RawTransactionObjectType[] = [];

  const assets = transferData.assets || [];

  if (assets.length) {
    assets.forEach((asset: AssetType) => {
      if (asset?.tokenDetails && asset?.tokenDetails.amount) {
        const obj: RawTransactionObjectType = {
          to: "",
          args: [],
          from: "",
          assetType: ASSET_TYPE.TOKEN,
          tokenSymbol: "",
        };

        const isCoin = checkIsNativeToken(asset?.tokenDetails?.address);

        obj.assetType = ASSET_TYPE.TOKEN;

        obj.to = isCoin ? transferData.address : asset.tokenDetails.address;

        obj.args = isCoin
          ? []
          : [
              transferData.address,
              ethers.utils
                .parseUnits(
                  asset.tokenDetails.amount.toString(),
                  asset.tokenDetails.decimal,
                )
                .toString(),
            ];

        obj.value = isCoin
          ? ethers.utils
              .parseEther(asset.tokenDetails.amount.toString())
              .toString()
          : "0";

        obj.from = smartAccountAddress;

        obj.tokenSymbol = asset.tokenDetails.symbol;

        obj.decimal = asset.tokenDetails.decimal;

        rawTransactionObject.push(obj);
      } else if (asset?.nftDetails && transferData.address) {
        const obj: RawTransactionObjectType = {
          to: "",
          args: [],
          from: "",
          assetType: ASSET_TYPE.TOKEN,
          nftName: "",
        };

        obj.assetType = ASSET_TYPE.NFT;
        obj.to = asset.nftDetails.address;
        obj.args = [
          smartAccountAddress,
          transferData.address,
          asset.nftDetails.id,
          "0x",
        ];

        obj.from = smartAccountAddress;

        obj.nftName = asset.nftDetails.name;

        rawTransactionObject.push(obj);
      }
    });
  }

  return rawTransactionObject;
};

export const errorHandler = (e: any, type: ERROR_EVENTS) => {
  window.dispatchEvent(new CustomEvent(type, { detail: e }));
};

export const customEventHandler = (data: any, type: CUSTOM_EVENTS) => {
  window.dispatchEvent(new CustomEvent(type, { detail: data }));
};

export const getNetworkErrorMessage = (chain1: any, chain2: any) => {
  const errorChainData = getChainDetails(chain1);
  const newChainData = getChainDetails(chain2);

  return ERROR_TOAST_MESSAGES.NETWORK_ACTIVATE_ERROR_TOAST.replace(
    "chain1",
    errorChainData.name,
  ).replace("chain2", newChainData.name);
};

const getExplorer = (chainId: number, allChains?: any) => {
  let chains = [];

  if (allChains && allChains.length) {
    chains = allChains;
  } else if (Chains && Chains.length) {
    chains = Chains;
  }

  const currentChain = chains.find((chain: any) => chain.chainId === chainId);
  const chainUrl = currentChain?.explorerUrl;

  if (!chainUrl) {
    const defaultChain = chains.find(
      (chain: any) => chain.chainId === DEFAULT_NETWORK.CHAIN_ID,
    );

    if (!defaultChain || !defaultChain?.explorerUrl)
      return `${DEFAULT_NETWORK.EXPLORER_URL}`;

    return `${defaultChain?.explorerUrl}`;
  }

  return `${chainUrl}`;
};

export const getExplorerAddressUrl = (
  chainId: number,
  allChains?: any,
  address?: string,
): string => {
  const url = getExplorer(chainId, allChains);

  if (address) return `${url}/address/${address}`;

  return `${url}/address/`;
};

export const getExplorerTxUrl = (chainId: number, allChains?: any): string => {
  const url = getExplorer(chainId, allChains);

  return `${url}/tx/`;
};

export const getExtensionURL = (route = null, queryString = null) => {
  let extensionURL = `${window.location.origin}/js/index.html`;

  if (route) {
    extensionURL += `#${route}`;
  }

  if (queryString) {
    extensionURL += `?${queryString}`;
  }

  return extensionURL;
};

export const checkExtensionApp = () => {
  if (
    window?.location?.origin?.includes("https://") ||
    window?.location?.origin?.includes("http://")
  )
    return false;

  return true;
};

export const getTime = (key: TIME) => LOCK_TIME[`${key}`];

export const setupMigrationData = async (): Promise<UserSettingsType[]> => {
  const legacyData = await getDevicesFromStorage();

  const isDeveloper = await getDevelopModeData();
  const time = getTime(TIME.HOUR_24);

  const mapData: any = {};

  const devicesData: UserSettingsType[] = [];

  legacyData.forEach((device: any) => {
    const r: DeviceType = {
      address: device?.address,
      hashCode: device?.hashCode,
      id: device?.id,
      name: device?.name,
      signer: device?.signer,
    };

    if (mapData[`${device.name}`]) {
      mapData[`${device.name}`].push(r);
    } else {
      mapData[`${device.name}`] = [r];
    }
  });

  Object.keys(mapData).forEach((mappedDataKey: any) => {
    const devices: DeviceType[] = mapData[`${mappedDataKey}`];

    const dummyDevice: UserSettingsType = {
      id: devices[0]?.id,
      hashCode: devices[0]?.hashCode,
      address: devices[0]?.signer, // EOA
      walletType: WALLET_TYPE.GENERATED,
      walletName: devices[0]?.name,
      mnemonic: devices[0]?.mnemonic || "",
      activeSmartAccountAddress: devices[0]?.address,
      accounts: [],
      settings: {
        // TODO update the accounts array and save all the smart accounts present of a perticular EOA and also generate different User Settings based on different eoa and when new eoa is change then update the user settings and delete all the things related to it in the local storage
        preferences: {
          theme: "default",
        },

        security: {
          autoLockTimer: time.minutes,
          autoLockTimerLabel: time.time,
          authenticationType: devices[0]?.id
            ? DEVICE_AUTH_TYPE.PASSKEY
            : DEVICE_AUTH_TYPE.NONE,
          password: null,
          biometrics: devices[0]?.id, // TODO update this line and encrypt it with the address to make it backwar compatable with the new version and also delete all the files from the local storage after making it happen.
        },
        recoveryPhrase: {
          seedPhraseBackedUp: false,
        },
        addressBook: [],
        transactionSettings: {
          connect: DEVICE_AUTH_TYPE.NONE,
          signMsg: DEVICE_AUTH_TYPE.NONE,
          approveTransaction: DEVICE_AUTH_TYPE.NONE,
        },
        developerMode: {
          showTestnet: isDeveloper,
          turnOffAds: isDeveloper,
        },
      },
    };

    devices.forEach((device: any, idx: number) => {
      dummyDevice.accounts.push({
        address: device.address,
        name: `Account ${idx}`,
      });
    });

    devicesData.push(dummyDevice);
  });

  return devicesData;
};

export const createUserSettingsFromDevice = (
  data: DeviceType,
  isGenerated: boolean,
  isNewRegistration: boolean,
): UserSettingsType => {
  const time = getTime(TIME.HOUR_24);

  return {
    id: data.id,
    hashCode: data.hashCode,
    address: data.signer, // EOA
    walletType: isGenerated ? WALLET_TYPE.GENERATED : WALLET_TYPE.IMPORTED,
    walletName: data.name,
    activeSmartAccountAddress: isNewRegistration ? "" : data?.address || "",
    mnemonic: data.mnemonic || "",
    accounts: [],
    settings: {
      preferences: {
        theme: "default",
      },
      // TODO update the accounts array and save all the smart accounts present of a perticular EOA and also generate different User Settings based on different eoa and when new eoa is change then update the user settings and delete all the things related to it in the local storage
      security: {
        autoLockTimer: time.minutes,
        autoLockTimerLabel: time.time,
        authenticationType:
          isNewRegistration || !data?.id
            ? DEVICE_AUTH_TYPE.NONE
            : DEVICE_AUTH_TYPE.PASSKEY,

        password: null,
        biometrics: isNewRegistration || !data?.id ? null : data?.id || null, // TODO update this line and encrypt it with the address to make it backwar compatable with the new version and also delete all the files from the local storage after making it happen.
      },
      recoveryPhrase: {
        seedPhraseBackedUp: false,
      },
      addressBook: [],
      transactionSettings: {
        connect: DEVICE_AUTH_TYPE.NONE,
        signMsg: DEVICE_AUTH_TYPE.NONE,
        approveTransaction: DEVICE_AUTH_TYPE.NONE,
      },
      developerMode: {
        showTestnet: false,
        turnOffAds: false,
      },
    },
  };
};

export const migrateOldDevices = async () => {
  const oldDevices = getDevicesFromStorage();

  if (oldDevices && oldDevices.length) {
    const migratedData: UserSettingsType[] = await setupMigrationData();

    setUserSettingsData(migratedData);
    removeItemFromStorage(STORAGE_KEYS.DEVICES);
  }
};

export const setAppVersion = async () => {
  await localforage.setItem(STORAGE_KEYS.APP_VERSION, manifest.version);
};

export const getAppVersion = async () => {
  const version = await localforage.getItem(STORAGE_KEYS.APP_VERSION);

  if (version) {
    return version;
  }

  await setAppVersion();
  return manifest.version;
};

export const getPassKeyAuthenticatorType = async (
  client: any,
): Promise<AuthType> => {
  const authenticator = client.isAvailable();
  const localAuthenticator = await client.isLocalAuthenticator();

  if (localAuthenticator) return "auto";

  if (authenticator) return "roaming";

  return "both";
};

export const hashFingerPrint = (fingerPrint: string): string => {
  if (!FP_KEY) throw new Error("Unable to create FP");

  const hash = CryptoJs.SHA256(fingerPrint).toString();
  const encrypted = CryptoJs.HmacSHA1(hash, FP_KEY).toString();

  return encrypted;
};

export const getUSDValue = async ({
  cryptoAsset,
  fiatCurrency,
}: {
  cryptoAsset: string;
  fiatCurrency: string;
}) => {
  const response = await fetch(
    `${FIAT_API}?fsym=${cryptoAsset}&tsyms=${fiatCurrency}&api_key=${FIAT_API_KEY}`,
  );

  const data = await response.json();

  return data.USD;
};

export const isEoaActive = (data: UserSettingsType) => data.isEoaSelected;

export const getEoaSigner = (pk: any, provider: any) => {
  if (!pk) throw new Error("Private key not defined");

  if (!provider) throw new Error("Provider not defined");

  const signer = new ethers.Wallet(pk, provider);

  return signer;
};

export const getEIP1193Chains = () => {
  const result = Chains.map((chain) => `eip155:${chain.chainId}`);

  return result;
};

export const getEIP1193Accounts = (address: string) => {
  const result = Chains.map((chain) => `eip155:${chain.chainId}:${address}`);

  return result;
};
