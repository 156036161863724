import { useEffect, useState } from "react";
import { FolderMinus } from "react-feather";
import { getSdkError } from "@walletconnect/utils";
import toast from "react-hot-toast";
import { DappsConnectionsType } from "../../constants/Types";
import {
  getDappConnectionsData,
  log,
  removeDappConnectionsData,
} from "../../utils/helper";
import ConnectionsCard from "./ConnectionsCard";
import useWallet from "../../lib/store/hooks/useWallet";
import IllustrationLoader from "../IllustrationLoader";
import { useConfig } from "../../context/ConfigProvider";

const WalletConnections = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [dappConnections, setDappConnectionsEntries] = useState<
    DappsConnectionsType[]
  >([]);

  const { smartAccountAddress } = useWallet();
  const { wcInstance } = useConfig();

  const fetchDappConnectionEntries = async (address: string) => {
    try {
      const connections: DappsConnectionsType[] = await getDappConnectionsData(
        address,
      );

      setDappConnectionsEntries(connections);
      setLoader(false);
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  useEffect(() => {
    if (smartAccountAddress) fetchDappConnectionEntries(smartAccountAddress);
  }, [smartAccountAddress]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 1100);

    return () => clearTimeout(timer);
  }, []);

  const disconnectDapp = async (
    topic: string,
    connectedWallet: string,
    hostname: string,
  ) => {
    try {
      setLoader(true);

      await wcInstance.disconnectSession({
        topic,
        reason: getSdkError("USER_DISCONNECTED"),
      });

      await removeDappConnectionsData(connectedWallet, hostname);
      toast.success("Disconnected successfully!");

      fetchDappConnectionEntries(connectedWallet);
    } catch (e) {
      setLoader(false);

      log("Error while disconnecting request ", { e }, "error");
      toast.error("Unable to disconnect, try again later.");
      fetchDappConnectionEntries(connectedWallet);
    }
  };

  return (
    <div className="w-full">
      {loader ? (
        <div className="w-full h-full flex justify-center items-center mt-44">
          <IllustrationLoader height="1.8em" />
        </div>
      ) : (
        <>
          {dappConnections && dappConnections.length === 0 ? (
            <>
              <div className="flex flex-col gap-2 justify-center items-center my-auto w-full h-fit px-5 py-6 bg-primary-bg rounded-2xl mt-28">
                <FolderMinus size={50} className=" text-neutral-200" />
                <p className="text-lg text-gray-200 "> No DApps Connected</p>
                <p className="text-sm text-gray-500 font-medium text-center ">
                  You have not connected to any dApps yet.
                </p>
              </div>
            </>
          ) : (
            <div className="w-full flex flex-col gap-3  max-h-[535px] overflow-auto pb-3">
              {dappConnections.map(
                (dappConnectionsData: DappsConnectionsType, idx: number) => (
                  <div key={idx}>
                    <ConnectionsCard
                      name={dappConnectionsData.name}
                      icon={dappConnectionsData.icon}
                      hostname={dappConnectionsData.hostname}
                      topic={dappConnectionsData.topic}
                      connectedWallet={dappConnectionsData.connectedWallet}
                      disconnectDapp={disconnectDapp}
                    />
                  </div>
                ),
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WalletConnections;
